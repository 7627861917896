import React, { useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/web/pdf_viewer.css';

// Worker file for pdf.js
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const PdfViewer = ({ fileUrl }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const renderPDF = async () => {
            try {
                const loadingTask = pdfjsLib.getDocument(fileUrl);
                const pdf = await loadingTask.promise;

                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);

                    // Calculate the scale factor to fit the page within the container
                    const containerWidth = containerRef.current.clientWidth;
                    const containerHeight = containerRef.current.clientHeight;
                    const viewport = page.getViewport({ scale: 1 });
                    const scale = Math.min(containerWidth / viewport.width, containerHeight / viewport.height);

                    const scaledViewport = page.getViewport({ scale });

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');

                    // Set the canvas width and height considering device pixel ratio
                    const outputScale = window.devicePixelRatio || 1;
                    canvas.width = Math.floor(scaledViewport.width * outputScale);
                    canvas.height = Math.floor(scaledViewport.height * outputScale);
                    canvas.style.width = `${scaledViewport.width}px`;
                    canvas.style.height = `${scaledViewport.height}px`;

                    // Scale the canvas context to account for the device pixel ratio
                    context.scale(outputScale, outputScale);

                    const renderContext = {
                        canvasContext: context,
                        viewport: scaledViewport,
                    };
                    await page.render(renderContext).promise;

                    containerRef.current.appendChild(canvas);
                }
            } catch (error) {
                console.error("Error loading PDF:", error);
                // Optionally show an error message to the user
            }
        };
        if (fileUrl.endsWith('.pdf')) {
            renderPDF();
        }
    }, [fileUrl]);

    if (fileUrl.match(/\.(jpeg|jpg|gif|png)$/)) {
        return (
            <div style={{ textAlign: 'center', height: '500px', border: '1px solid black' }}>
                <img src={fileUrl} alt="Document" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            </div>
        );
    }

    return (
        <div
            ref={containerRef}
            style={{
                textAlign: 'center',
                overflowY: 'scroll',
                height: '500px',
                border: '1px solid black',
            }}
        />
    );
};

export default PdfViewer;