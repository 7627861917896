import React from 'react';
import { useAuth } from '../../contexts/AuthContext';

const HelpComponent = () => {
    const { user } = useAuth();

    const guardianContent = (
        <div>
            <h1>Help Page for Parents</h1>
            <main style={styles.main}>
                <section>
                    <h3 style={styles.heading}>Adding Students to Your Account</h3>
                    <ul style={styles.ul}>
                        <li style={styles.li}>Go to <strong>Dashboard</strong> <strong>Student(s)</strong>.</li>
                        <li style={styles.li}>Click <strong>Add a New Student</strong>.</li>
                        <li style={styles.li}>Fill in your child’s details and assign a color of your choice.</li>
                        <li style={styles.li}>Click <strong>Confirm Student</strong>.</li>
                    </ul>

                    <h3 style={styles.heading}>Shopping and Registering for Courses</h3>
                    <ul style={styles.ul}>
                        <li style={styles.li}>Go to <strong>Courses</strong> from the sidebar.</li>
                        <li style={styles.li}>Browse available courses and click <strong>Register</strong>.</li>
                        <li style={styles.li}>Select a student to register into the course and click <strong>Confirm</strong>.</li>
                        <li style={styles.li}>After all courses are added, click <strong>Checkout</strong>.</li>
                        <li style={styles.li}>Complete the payment process to confirm the registration.</li>
                    </ul>

                    <h3 style={styles.heading}>Booking Classes for Courses</h3>
                    <ul style={styles.ul}>
                        <li style={styles.li}>Click on a student under <strong>Student(s)</strong>.</li>
                        <li style={styles.li}>Click <strong>Manage Classes</strong>.</li>
                        <li style={styles.li}>Click <strong>Book</strong> for the class you want to book.</li>
                        <li style={styles.li}>Choose from available (blue) dates and times for the classes.</li>
                        <li style={styles.li}>Confirm the booking by clicking <strong>Book</strong>.</li>
                    </ul>

                </section>
                If your concerns are not resolved, please email info@thecodeinitiative.ca

            </main>
        </div>
    );

    const tutorContent = (
        <div>
            <h1>Help Page for Tutors</h1>
            <main style={styles.main}>
                <section>
                    <h3 style={styles.heading}>Adding Availability</h3>
                    <ul style={styles.ul}>
                        <li style={styles.li}>Go to <strong>Availability</strong> in the sidebar.</li>
                        <li style={styles.li}>Toggle between <strong>Recurring</strong> and <strong>Weekly</strong> as required.</li>
                        <li style={styles.li}>Update your maximum teaching hours by dragging over the desired date and time.</li>
                        <li style={styles.li}>Click <strong>Submit</strong>.</li>
                    </ul>

                    <h3 style={styles.heading}>Canceling Appointments</h3>
                    <ul style={styles.ul}>
                        <li style={styles.li}>Go to <strong>Appointments</strong> in the sidebar.</li>
                        <li style={styles.li}>Select a student and course and click <strong>Search Enrollments</strong>.</li>
                        <li style={styles.li}>Click on the appointment in the calendar, and confirm deletion.</li>
                    </ul>
                    <h3 style={styles.heading}>View Course Curriculum</h3>
                    <ul style={styles.ul}>
                        <li style={styles.li}>Go to <strong>Courses</strong> in the sidebar.</li>
                        <li style={styles.li}>Select the course you would like to view, please note that you are only able to access curriculum for courses you are registered to teach.</li>
                    </ul>

                    <h3 style={styles.heading}>Go to Class Notes</h3>
                    <ul style={styles.ul}>
                        <li style={styles.li}>Go to <strong>Dashboard</strong> in the sidebar.</li>
                        <li style={styles.li}>Navigate to <strong>Schedule</strong> in the top menubar.</li>
                        <li style={styles.li}>Click on the class you would like to add notes to. Please note you can only access notes for booked classes. This feature is not available for trial classes; clicking on the class will directly lead you to the meeting link for trial classes.</li>
                    </ul>
                </section>
            </main>
        </div>
    );

    return (
        <div>
            {user.role === 'guardian' && guardianContent}
            {user.role === 'tutor' && tutorContent}
            {user.role === 'admin' && (
                <h1>Hello Admin</h1>
            )}
        </div>
    );
};

const styles = {
    header: {
        backgroundColor: '#007BFF',
        color: 'white',
        padding: '1rem 2rem',
        textAlign: 'center',
    },
    main: {
        padding: '2rem',
        maxWidth: '800px',
        margin: 'auto',
        background: 'white',
        textAlign: 'left',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    },
    heading: {
        color: '#007BFF',
    },
    ul: {
        margin: '1rem 0',
        paddingLeft: '1.5rem',
    },
    li: {
        margin: '0.5rem 0',
    },
};

export default HelpComponent;