import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import { useAuth } from '../../contexts/AuthContext';

import "./index.css";

/* PIE CHART CONFIG */
const API_URL = process.env.REACT_APP_API_URL

export const PieData = [
  ["Course", "Hours Taught"],
  ["Python", 11],
  ["Java", 2],
  ["JS", 2],
  ["Scratch", 2],
];

export const PieOptions = {
  title: "",
  colors: ['#103DA2', '#343434', '#7A8CA6', '#ACB6C6'],
  legend: {
    position: 'right', // Set legend position to bottom
    alignment: 'center', // Optionally, center align the legend
  },
};

/* LINE CHART CONFIG */
export const LineOptions = {
  title: "",
  legend: "none",
};

export const tutorBio = `
  Samantha is a seasoned educator with a deep love for technology 
  and a knack for making coding fun and accessible for young minds. 
  Holding a Bachelor's degree in Computer Science and Education, 
  she brings a unique blend of technical expertise and pedagogical skills 
  to the learning environment. 
`;


export const TutorAnalytics = ({ id }) => {
  // Define state to manage the bio content, allow editing
  const [bioContent, setBioContent] = useState(tutorBio);
  const [editMode, setEditMode] = useState(false);
  const [tempBioContent, setTempBioContent] = useState('');
  const [dashInfo, setDashInfo] = useState({});
  const [reviews, setReviews] = useState([]);
  const { user } = useAuth();

  const handleEditClick = () => {
    setTempBioContent(bioContent);
    setEditMode(true);
  };

  const handleInputChange = (event) => {
    setTempBioContent(event.target.value);
  };

  const handleSaveClick = () => {
    setBioContent(tempBioContent);
    setEditMode(false);
  };

  const handleCancelClick = () => {
    setTempBioContent(bioContent); // Restore original bio content
    setEditMode(false); // Exit edit mode
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = user.userId; // Assuming user.id is available
        const url = `${API_URL}/tutor/dash/?id=${id}`;

        // Make the API request using the constructed URL
        const response = await fetch(url);
        const data = await response.json();
        console.log("Printing ", data);
        setDashInfo(data);

        const reviewsResponse = await fetch(`${API_URL}/reviews?id=${id}`);
        const reviewsData = await reviewsResponse.json();
        console.log(`Reviews Data: ${reviewsData}`);
        setReviews(reviewsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [user]);

  const LineData = [
    ["Month", "Hours Taught"],
    ...(dashInfo.monthlyBookings ? dashInfo.monthlyBookings.slice(-6).map(item => [item.month_name, parseInt(item.bookings_count)]) : [])
  ];

  const getData = (tf) => {
    let pieData = [];
    if (tf === 'Month') {
      pieData = [
        ["Course", "Hours Taught"],
        ...dashInfo.courseHoursTaughtCurrentMonth.map(item => [item.course_name, parseFloat(item.hours_taught)])
      ];
    } else {
      pieData = [
        ["Course", "Hours Taught"],
        ...dashInfo.courseHoursTaught.map(item => [item.course_name, parseFloat(item.hours_taught)])
      ];
    }
    return pieData;
  }


  const getHours = (tf) => {
    let totalHours = 0;
    if (tf === 'Month') {
      totalHours = dashInfo.courseHoursTaughtCurrentMonth.reduce((sum, item) => sum + parseFloat(item.hours_taught), 0);
    } else {
      totalHours = dashInfo.courseHoursTaught.reduce((sum, item) => sum + parseFloat(item.hours_taught), 0);
    }
    return totalHours;
  }

  // change calculation based on timeframe
  const totalHours = PieData.slice(1).reduce((sum, [, hours]) => sum + hours, 0);
  const [timeframe, setTimeframe] = useState('Month');
  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  return (
    <div className='analytics-grid'>
      {dashInfo && dashInfo.tutorOverview &&
        <div className='grid-item bio-container '>
          <div className='subtitle'>
            <h2>Impact Overview</h2>
          </div>
          <div className='impact-elements'>
            <div>
              <p>Total Hours</p>
              <h2>{dashInfo.tutorOverview[0].total_hours}</h2>
              <div>
                <p>Classes</p>
                <h2>{dashInfo.tutorOverview[0].classes_taught}</h2>
              </div>
            </div>
            <div>
              <div>
                <p>Students Taught</p>
                <h2>{dashInfo.tutorOverview[0].total_students}</h2>
              </div>
              <p>Trials</p>
              <h2>{dashInfo.tutorOverview[0].trials_taught}</h2>
            </div>
          </div>
        </div>
      }

      <div className='grid-item feedback-container'>
        <div className='subtitle'>
          <h2>Parent Reviews</h2>
        </div>
        <div className='scroll-feedback'>
          <div className='scroll-feedback'>
            {reviews.map((review) => (
              <div className='feedback-content' key={review.id}>
                <h4>{review.guardian_name}</h4>
                <p>{review.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='grid-item line-chart-container'>
        <div className='subtitle'>
          <h2>Lessons Taught</h2>
        </div>
        <div className='line-content'>
          <Chart
            chartType="LineChart"
            data={LineData}
            options={LineOptions}
            width={"100%"}
            height={"60vh"}
          />
        </div>
      </div>

      {dashInfo && dashInfo.courseHoursTaught &&
        <div className='grid-item pie-chart-container'>
          <div className='subtitle'>
            <h2>Hours Taught</h2>
            <select value={timeframe} onChange={handleTimeframeChange}>
              <option value="Year">Year</option>
              <option value="Month">Month</option>
            </select>
          </div>
          <div className='pie-content'>
            <Chart
              chartType="PieChart"
              data={getData(timeframe)}
              options={PieOptions}
              width={"100%"}
              height={"45vh"}
            />
            <div className='text-content'>
              <p>Total Hours Taught this {timeframe}:</p>
              <h2>{getHours(timeframe)}</h2>
            </div>
          </div>
        </div>}
    </div>
  );
};

export default TutorAnalytics;