import "./index.css";
import ParentDashCalendar from "../../components/ParentDashCalendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./index.css";
import { useState, useEffect } from "react";
import { ParentDashboardLayout } from "../../components/ParentDashboardLayout";
import { useNavigate } from "react-router-dom";
import { MainContentLayout } from "../../components/MainContentLayout";
import { NavLink } from 'react-router-dom';
import { AddReviewForm } from "../../components/AddReviewForm";
import { useAuth } from "../../contexts/AuthContext";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AgendaView } from "./agenda";
import Cookies from 'js-cookie';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import { RemoveCircleOutline } from "@mui/icons-material";

const URL = process.env.REACT_APP_API_URL

const ParentDash = (props) => {
    const navigate = useNavigate();
    const [students, setStudents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const { user, login } = useAuth()
    const guardian_id = user.userId;

    const editStudent = async (studentId) => {
        navigate(`/editStudent/${studentId}`);
    };

    const deleteStudent = async (studentId, name) => {
        // Confirm before proceeding with the deletion
        const confirmed = window.confirm("Are you sure you want to delete " + name + "?");

        if (confirmed) {
            const url = URL + `/students/${studentId}`;
            try {
                const response = await fetch(url, {
                    method: 'DELETE', // Use DELETE method to remove the student
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                // Call the function to refetch the students after successful deletion
                fetchStudents();
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        }
    };

    const handleTileClick = async (studentId) => {


        navigate(`/student/${studentId}`);

    };

    const fetchStudents = async () => {
        const url = URL + `/guardian/students`;
        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + Cookies.get('token')
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();



            const updatedUser = {
                ...user,  // Create a new object with the existing user properties
                children: data,  // Replace the children array with the new data
            };


            login(updatedUser)
            setStudents(data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }


    useEffect(() => {
        fetchStudents();
    }, [])

    const handleReviewClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <MainContentLayout
            rightColumnContent={
                <div
                    className="pc"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                        marginRight: "15px"

                    }}>
                    <button style={{
                        padding: "10px",
                        backgroundColor: "#007BFF", // Change this to match your theme color
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "16px",
                        transition: "all 0.3s ease"
                    }}
                        onClick={handleReviewClick}
                        onMouseOver={(e) => e.target.style.backgroundColor = "#0069d9"} // Darker shade on hover
                        onMouseOut={(e) => e.target.style.backgroundColor = "#007BFF"} // Original color on mouse out
                    >
                        Add Tutor Review
                    </button>
                    <AddReviewForm showModal={showModal} handleCloseModal={handleCloseModal} guardianId={guardian_id} />
                </div>
            }>
            <div className="student-info-container">
                <div className="student-info-header">
                    <h2>Student(s)</h2>

                    <div
                        className="pc"
                        style={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically in the center
                            gap: '10px', // Add space between the elements
                        }}
                    >
                        <NavLink
                            to="/addStudent"
                            className="header-button"
                            style={{
                                fontSize: '18px', // Increase font size
                                padding: '12px 20px', // Increase padding
                                height: '3rem', // Increase height
                                fontWeight: 'bold', // Increase font weight
                                borderRadius: '15px', // Keep the border radius consistent
                                backgroundColor: '#B3DEFC', // Ensure background color is consistent
                                color: '#103DA2', // Ensure text color is consistent
                                border: 'none', // Ensure border is consistent
                                cursor: 'pointer', // Ensure cursor is consistent
                            }}
                        >
                            Add a New Student
                        </NavLink>

                        <button
                            style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
                            onClick={() => setEditMode(!editMode)}
                        >
                            {editMode ?
                                <EditOffIcon style={{ fill: "#103da2", fontSize: "40px" }} /> :
                                <EditIcon style={{ fill: "#103da2", fontSize: "40px" }} />
                            }
                        </button>

                        <button
                            style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
                            onClick={() => setDeleteMode(!deleteMode)}
                        >
                            {deleteMode ?
                                <DeleteForeverIcon style={{ fill: "#103da2", fontSize: "40px" }} /> :
                                <DeleteIcon style={{ fill: "#103da2", fontSize: "40px" }} />
                            }
                        </button>

                    </div>

                    <div className="mb" style={{ marginTop: '10px' }}>
                        <NavLink to="/addStudent">
                            <AddCircleIcon style={{ fill: "#103da2", fontSize: "40px" }} />
                        </NavLink>
                        <button
                            style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
                            onClick={() => setEditMode(!editMode)}
                        >
                            {editMode ?
                                <EditOffIcon style={{ fill: "#103da2", fontSize: "40px" }} /> :
                                <EditIcon style={{ fill: "#103da2", fontSize: "40px" }} />
                            }
                        </button>
                        <button
                            style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
                            onClick={() => setDeleteMode(!deleteMode)}
                        >
                            {deleteMode ?
                                <DeleteForeverIcon style={{ fill: "#103da2", fontSize: "40px" }} /> :
                                <DeleteIcon style={{ fill: "#103da2", fontSize: "40px" }} />
                            }
                        </button>
                    </div>

                </div>
                <div>
                    <div className="pc existing-students-row" >
                        {
                            students.map((student) => {
                                const tileColorClass = `student-tile ${student.color}-tile`;
                                return (
                                    <div
                                        key={student.student_id}
                                        className={tileColorClass}
                                        style={{ position: 'relative', display: 'inline-block' }} // Make sure the parent is relatively positioned
                                        onClick={() => handleTileClick(student.student_id)}
                                    >
                                        {editMode && (
                                            <EditIcon
                                                onClick={(event) => {
                                                    event.stopPropagation(); // Prevent the click from propagating to the parent div
                                                    editStudent(student.student_id);
                                                }} style={{
                                                    position: 'absolute', // Absolute positioning for the icon
                                                    top: '2',   // Align to the top
                                                    right: '2',  // Align to the left
                                                    fill: '#ab5702', // Change color to orange
                                                    cursor: 'pointer', // Make it clickable
                                                }}
                                            />
                                        )}

                                        {deleteMode && (
                                            <RemoveCircleIcon
                                                onClick={(event) => {
                                                    event.stopPropagation(); // Prevent the click from propagating to the parent div
                                                    deleteStudent(student.student_id, student.name);
                                                }} style={{
                                                    position: 'absolute', // Absolute positioning for the icon
                                                    top: '2',   // Align to the top
                                                    right: '2',  // Align to the left
                                                    fill: '#FF0000',
                                                    cursor: 'pointer', // Make it clickable
                                                }}
                                            />

                                        )}

                                        <div className="rectangle"></div>
                                        <div className="name">{student.name}</div>
                                    </div>

                                )
                            })


                        }
                    </div>
                    <div className="mb student-container">

                        {students.map((student) => {
                            const tileColorClass = `stripe ${student.color}-tile`;

                            return (

                                <div key={student.student_id} className="name-card" onClick={() => handleTileClick(student.student_id)}>
                                    {editMode && (
                                        <EditIcon
                                            onClick={(event) => {
                                                event.stopPropagation(); // Prevent the click from propagating to the parent div
                                                editStudent(student.student_id);
                                            }} style={{
                                                position: 'absolute', // Absolute positioning for the icon
                                                top: '2',   // Align to the top
                                                right: '2',  // Align to the left
                                                fill: '#ab5702', // Change color to orange
                                                cursor: 'pointer', // Make it clickable
                                            }}
                                        />
                                    )}
                                    {deleteMode && (
                                        <RemoveCircleIcon
                                            onClick={(event) => {
                                                event.stopPropagation(); // Prevent the click from propagating to the parent div
                                                deleteStudent(student.student_id, student.name);
                                            }} style={{
                                                position: 'absolute', // Absolute positioning for the icon
                                                top: '5',   // Align to the top
                                                right: '5',  // Align to the left
                                                fill: '#FF0000',
                                                cursor: 'pointer', // Make it clickable
                                            }}
                                        />
                                    )}
                                    <div className={tileColorClass}></div>
                                    <div className="content">
                                        {student.name}
                                    </div>
                                </div>

                            )
                        })}
                    </div>

                </div>

                <button
                    className="mb"
                    style={{
                        margin: "20px",
                        padding: "10px",
                        backgroundColor: "#007BFF", // Change this to match your theme color
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "16px",
                        transition: "all 0.3s ease"
                    }}
                    onClick={handleReviewClick}
                    onMouseOver={(e) => e.target.style.backgroundColor = "#0069d9"} // Darker shade on hover
                    onMouseOut={(e) => e.target.style.backgroundColor = "#007BFF"} // Original color on mouse out
                >
                    Add Tutor Review
                </button>
            </div >
            <div className="mb">
                <ParentDashCalendar students={students} defaultView="agenda" views={{ day: true, agenda: AgendaView }} ></ParentDashCalendar>
            </div>

            <div className="pc">
                <ParentDashCalendar students={students}></ParentDashCalendar>
            </div>
        </MainContentLayout >
    );
};

export default ParentDash;